import axios from "axios";
import { setupInterceptorsTo } from "./interceptors";
console.log("Base URL:", process.env.REACT_APP_API_URL);
const api = setupInterceptorsTo(
  axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    headers: {
      "Content-Type": "application/json",
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Headers":
      "Origin, X-Requested-With, Content-Type, Accept, Z-Key",
      "Access-Control-Allow-Methods": "GET, HEAD, POST, PUT, DELETE, OPTIONS",
    },
    withCredentials: true,
  })
);

export default api;
