import {
  FormControl,
  FormLabel,
  FormErrorMessage,
  FormHelperText,
  Input,
} from "@chakra-ui/react";
import { Button, ButtonGroup } from "@chakra-ui/react";
import { useAtom } from "jotai";
import { userAtom } from "../atoms";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { loginUser } from "../api";
import { useState } from "react";
export const Login = () => {
  const [email, setEmail] = useState<string | null>();
  const [password, setPassword] = useState<string | null>();
  const [user, setUser] = useAtom(userAtom);
  const navigate = useNavigate();
  const onSubmit = async () => {
    try {
      const response = await loginUser({
        email: email,
        password: password,
      });
      setUser(response.data);
      navigate("/dashboard");
    } catch {
      alert("Invalid email or password");
    }
  };
  return (
    <>
      <FormControl>
        <FormLabel>Email address</FormLabel>
        <Input type="email" onChange={(e: any) => setEmail(e.target.value)} />
      </FormControl>
      <FormControl>
        <FormLabel>Password</FormLabel>
        <Input
          type="password"
          onChange={(e: any) => setPassword(e.target.value)}
        />
      </FormControl>
      <Button colorScheme="blue" onClick={onSubmit}>
        Login
      </Button>
    </>
  );
};
