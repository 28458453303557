import {
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer,
  Button,
  Input,
  Heading,
} from "@chakra-ui/react";
import { useEffect, useRef, useState } from "react";
import {
  getAllSettings,
  getAllUsers,
  getUserCount,
  impersonateUser,
  updateEnvironmentLogo,
  updateSettingValue,
  updateUserStatus,
} from "../api/admin";
import { Select } from "@chakra-ui/react";
import * as _ from "lodash";
import { AxiosError } from "axios";
import { RangeDatepicker, SingleDatepicker } from "chakra-dayzed-datepicker";

import { getContractCSV, getContractPDFZip } from "../api/contract";
export const Dashboard = () => {
  const [userMap, setUserMap] = useState<any>({});
  const [users, setUsers] = useState([]);
  const profileImageFile = useRef<HTMLInputElement | null>(null);

  const [settingMap, setSettingMap] = useState<any>({});
  const [settings, setSettings] = useState([]);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [userCount, setUserCount] = useState();

  const toBase64 = (file: any): Promise<any> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result?.toString()?.split(",")[1]);
      reader.onerror = (error) => reject(error);
    });

  const onProfileImageSelected = async (event: any) => {
    event.stopPropagation();
    event.preventDefault();
    const file = event.target.files[0];

    const imageUrl = URL.createObjectURL(file);
    const base64: string = await toBase64(file);
    try {
      alert("Uploading logo");
      await updateEnvironmentLogo(base64);
      alert("Uploaded logo");
    } catch (error) {
      alert("Failed to upload logo");
      console.error(error);
    }
  };

  const loadUsers = async () => {
    try {
      const response = await getAllUsers();
      const users = response.data;
      setUsers(users);
      if (users.length > 0) {
        const cloned = _.cloneDeep(users);
        const tempMap = _.keyBy(cloned, "_id");
        setUserMap(tempMap);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const loadSettings = async () => {
    try {
      const response = await getAllSettings();
      const settings = response.data;
      setSettings(settings);
      if (settings.length > 0) {
        const cloned = _.cloneDeep(settings);
        const tempMap = _.keyBy(cloned, "_id");
        setSettingMap(tempMap);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const countUsers = async () => {
    const response = await getUserCount(startDate, endDate);
    setUserCount(response?.data?.numberOfUsers);
  };
  useEffect(() => {
    loadUsers();
    loadSettings();
  }, []);
  useEffect(() => {
    if (startDate != null && endDate != null) {
      countUsers();
    }
  }, [startDate, endDate]);
  useEffect(() => {}, [users]);
  const handleDropdownCHange = (e: any, idx: number) => {
    let tmp: any = _.cloneDeep(users);
    tmp[idx].role = e.target.value;
    setUsers(tmp);
  };
  const handleSettingInputChange = (e: any, idx: number) => {
    let tmp: any = _.cloneDeep(settings);
    tmp[idx].value = e.target.value;
    setSettings(tmp);
  };
  const setUserStatus = async (user: any) => {
    await updateUserStatus(user);
    const tmp = _.cloneDeep(userMap);
    tmp[user._id] = user;
    setUserMap(tmp);
  };

  const setSettingStatus = async (setting: any) => {
    try {
      await updateSettingValue(setting);
      const tmp = _.cloneDeep(settingMap);
      tmp[setting._id] = setting;
      setSettingMap(tmp);
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.message);
      }
    }
  };
  const downloadCsv = async () => {
    const response = await getContractCSV(startDate, endDate);
    const href = window.URL.createObjectURL(
      new Blob([response.data], {
        type: "text/csv",
      })
    );

    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "contracts.csv"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
  };

  const downloadPDFZip = async () => {
    const response = await getContractPDFZip(startDate, endDate);
    const href = response.request?.responseURL;
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", "contracts.zip"); //or any other extension
    document.body.appendChild(link);
    link.click();

    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link);
    window.URL.revokeObjectURL(href);
  };
  const impersonate = async (user: any) => {
    try {
      const response = await impersonateUser(user);
      const url = response?.data?.url;
      if (url != null) {
        window.location.href = url;
      }
    } catch (error) {
      if (error instanceof AxiosError) {
        alert(error.response?.data?.message);
      }
    }
  };
  return (
    <>
      <Heading>User Count {userCount != null && <>{userCount}</>}</Heading>
      <SingleDatepicker
        name="date-input"
        date={startDate}
        onDateChange={setStartDate}
      />
      <SingleDatepicker
        name="date-input"
        date={endDate}
        onDateChange={setEndDate}
      />
      <Button
        onClick={() => {
          downloadCsv();
        }}
      >
        Download Contract Data
      </Button>
      <Button
        onClick={() => {
          downloadPDFZip();
        }}
      >
        Download Contract PDF Zip
      </Button>
      <Button
        onClick={() => {
          profileImageFile.current?.click();
        }}
      >
        Update Environment Logo
      </Button>
      <Heading>Settings</Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Setting</Th>
              <Th>Value</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {settings.map((setting: any, idx) => {
              return (
                <>
                  <Tr key={setting._id}>
                    <Td>{setting.name}</Td>
                    <Td>
                      <Input
                        value={setting.value}
                        onChange={(e) => handleSettingInputChange(e, idx)}
                      />
                    </Td>
                    <Td>
                      {settingMap?.[setting._id]?.value != setting.value && (
                        <Button
                          onClick={() => {
                            setSettingStatus(setting);
                          }}
                        >
                          Save
                        </Button>
                      )}
                    </Td>
                  </Tr>
                </>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <Heading>Users</Heading>
      <TableContainer>
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th>Name</Th>
              <Th>Email</Th>
              <Th>Role</Th>
              <Th>Registration Date</Th>
              <Th>Last Login</Th>
              <Th>Reset Code</Th>
              <Th>Verification Code</Th>
              <Th>Action</Th>
            </Tr>
          </Thead>
          <Tbody>
            {users.map((user: any, idx) => {
              return (
                <>
                  <Tr key={user._id}>
                    <Td>
                      {user.firstName} {user.lastName}
                    </Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <Select
                        onChange={(e) => {
                          handleDropdownCHange(e, idx);
                        }}
                      >
                        <option
                          value="RegularUser"
                          selected={user.role == "RegularUser"}
                        >
                          Regular User
                        </option>
                        <option
                          value="BlockedUser"
                          selected={user.role == "BlockedUser"}
                        >
                          Blocked User
                        </option>
                        <option
                          value="UnverifiedUser"
                          selected={user.role == "UnverifiedUser"}
                        >
                          Unverified User
                        </option>
                      </Select>
                    </Td>
                    <Td> {user.createdAt}</Td>
                    <Td> {user.lastLogin}</Td>
                    <Td>{user.resetCode}</Td>
                    <Td>{user.verificationCode}</Td>
                    <Td>
                      {userMap?.[user._id]?.role != user.role && (
                        <Button
                          onClick={() => {
                            setUserStatus(user);
                          }}
                        >
                          Save
                        </Button>
                      )}
                      {user?.role != "BlockedUser" && (
                        <Button
                          onClick={() => {
                            impersonate(user);
                          }}
                        >
                          Impersonate
                        </Button>
                      )}
                    </Td>
                  </Tr>
                </>
              );
            })}
          </Tbody>
        </Table>
      </TableContainer>
      <input
        type="file"
        id="file"
        ref={profileImageFile}
        style={{ display: "none" }}
        onChange={onProfileImageSelected}
        accept="image/png"
      />
    </>
  );
};
