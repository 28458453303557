import { atom, WritableAtom } from "jotai";

export const userAtom = atom<{
  _id: string;
  email: string;
  firstName: string;
  lastName: string;
  role?: string;
} | null>(null);

export const loadingAtom = atom<boolean>(true);
