import React from "react";

import { useAtom } from "jotai";
import { Navigate } from "react-router-dom";
import { loadingAtom, userAtom } from "../atoms";

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const [user] = useAtom(userAtom);
  const [loading] = useAtom(loadingAtom);
  if (loading === false) {
    if (user == null) {
      return <Navigate to="/login" />;
    }
    if (user.role != "AdminUser") {
      return <Navigate to="/login" />;
    }
  }
  return children;
};

export default ProtectedRoute;
